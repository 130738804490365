import { Link, Container, PortableText } from '@aether/components'
import { ErrorPageResponse } from '@aether/models'
import {
  getErrorPage,
  getSiteConfiguration,
} from '@aether/services/sanity-service'
import { styled } from '@aether/styles'
import { GetStaticProps, NextPage } from 'next'
import { REVALIDATE_NOT_FOUND } from '@aether/configs'
import { getActiveDiscounts } from '@aether/discounts/data-access'
import { NextSeo } from 'next-seo'

const Root = styled('div', {
  $posterHeight: true,
  display: 'flex',
  alignItems: 'center',
})

const StyledContainer = styled(Container, {
  textAlign: 'center',
})

const Title = styled('h1', {
  $aetherFont: 'heading01',
  marginBottom: '-$4',

  $lin: {
    fontSize: [80, 140],
  },
})

const Description = styled('div', {
  marginBottom: '$32',
  color: '$gray_medium',
})

const Custom404: NextPage<ErrorPageResponse> = ({ pageData }) => {
  return (
    <Root>
      <NextSeo title={'Page Not Found - AETHER Apparel'} />
      <StyledContainer>
        {pageData.title && <Title>{pageData.title}</Title>}
        <Description>
          {pageData.description && (
            <PortableText value={pageData.description} withSpacing={false} />
          )}
        </Description>
        {pageData.ctaLabel && pageData.ctaLink && (
          <Link size="L" {...pageData.ctaLink} appearance="badgeBlack">
            {pageData.ctaLabel}
          </Link>
        )}
      </StyledContainer>
    </Root>
  )
}

export const getStaticProps: GetStaticProps<ErrorPageResponse> = async ({
  locale,
  preview = false,
}) => {
  const siteConfiguration = await getSiteConfiguration(locale, preview)
  const pageData = await getErrorPage(locale, preview, 'notFound')
  const discounts = await getActiveDiscounts()
  return {
    props: {
      siteConfiguration,
      pageData,
      hideFooter: false,
      hideHeader: false,
      isHeaderStatic: true,
      allDiscounts: discounts,
    },
    revalidate: REVALIDATE_NOT_FOUND,
  }
}

export default Custom404
